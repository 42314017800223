import React from "react"
import { FaHome } from "react-icons/fa"

export default function ErrorPage() {
  return (
    <div className="container main">
      <div className="display-3 text-center">404 Page Not Found</div>
      <div className="my-5"></div>
      <div className="text-center h3" style={{ color: "#1f2b3b" }}>
        <a href="/">
          Return to <FaHome />
        </a>
      </div>
    </div>
  )
}
